<template>
    <div class="pd20x flex1">
        <div class="contentBox h100pct ofA">
            <!-- <div class="filter" style="padding:10px 20px">
                <div>
                </div>
            </div> -->
            <div class="order-table">
                <div class="tableHeader">
                    <div style="width:250px">商品信息</div>
                    <div style="width:150px">售价</div>
                    <div style="width:150px">状态</div>
                    <div style="width:120px">交易金额</div>
                    <div style="width:120px">买家/收货人</div>
                    <div style="width:200px">快递/快递单号</div>
                    <div style="width:200px">地址</div>
                    <div style="flex:1">备注</div>
                    <div style="width:90px">操作</div>
                </div>
                <template v-for="item in data">
                   <div class="order-table-body" :key="item.id">
                       <div class="orderHeader">
                           <div>
                               <span class="mr20px">订单号：{{item.order_no}}</span>
                               <span>下单时间：{{item.create_time}}</span>
                           </div>
                           <div></div>
                       </div>
                       <div class="order-table-content">
                            <div style="width:250px;" class="vertical">
                                <div v-for="order in item.list" :key="order.id" class="inline">
                                  {{order.cname}}
                                </div>
                            </div>
                            <div style="width:150px;" class="vertical">
                                <div v-for="(price, index) in item.list" :key="index" class="inline">
                                  {{price.now_price}} x {{price.num}}
                                </div>
                            </div>
                            <div style="width:150px">{{item.status?item.status==1?'已支付':'已发货':'待支付'}}</div>
                            <div style="width:120px">{{item.price}}</div>
                            <div style="width:120px">{{item.nickname}}<br>{{item.username}}</div>
                            <div style="width:200px; display: block">
                                <div>{{item.kdname}}</div>
                                <div>{{item.express_no}}</div>
                            </div>
                            <div style="width:200px">
                                <div class="copy" 
                                   @click="copyAddress(item.address, $event)"
                                >
                                    {{item.address.real_name}} {{item.address.phone}}
                                    <br>
                                    {{item.address.province}} {{item.address.city}} {{item.address.detail}}
                                </div>
                            </div>
                            <div style="flex:1">{{item.remark}}</div>
                            <div style="width:90px">
                                <span v-if="item.status ==1" class="csP" style="color:#7ebae5" @click="sendOrder(item)">发货</span>
                            </div>
                       </div>
                   </div>
                </template>
            </div>
            <a-pagination :show-total="total => `共 ${total} 条`" v-model="pagination.page" :total="total" @change="changePage"/>
        </div>
        <a-modal
            title="快递单号"
            :visible="expressShow"
            :confirm-loading="confirmLoading"
            @ok="handleOk"
            @cancel="expressShow = false"
            centered
        >
            <a-input placeholder="请输入快递单号" v-model="express_no" />
        </a-modal>
    </div>
</template>
<script>
const columns = [

];
import { formatDay } from '@/libs/moment'
import * as http from '@/libs/examapi'
import { routerlink } from '@/mixins/routerlink'
export default {
    name: 'purchase',
    mixins: [routerlink],
    data(){
        return{
            options: [],
            columns,
            filter:{
            },
            pagination:{
                page: 1,
                size: '15',
                pageSize: 15
            },
            total:0,
            allSubject:[],
            data: [],
            areaval: '',
            //填写快递
            expressShow: false,
            confirmLoading: false,
            express_no:null,
            currentItem: {}
        }
    },
    mounted(){
        this.getData()
    },
    methods:{
        formatDay,
        getData(){
            http.getOrderList({...this.pagination})
            .then(res => {
                let { data, total } = res
                this.total = total
                this.data = data
            }).catch(error=>{
                console.log(error)
            })
        },
        handleChange(){

        },
        deleteItem(id){
            let _this = this
            this.$confirm({
                title: '是否确定删除该条信息?',
                centered: true,
                onOk() {
                    http.del_crouse_bag({id}).then(res=>{
                        let {page, pagesize, total} = _this.pagination
                        if(page>1 && Number(page-1)* 15 == total - 1){
                            _this.pagination.page = Number(page-1)
                        }
                        _this.getData()
                    }).catch(error=>{
                        console.log(error)
                    })
                },
            });
        },
        changePage({current}){
            this.pagination.page = current
            this.getData()
        },
        sendOrder(data){
            this.express_no = null
            this.currentItem = data
            this.expressShow = true
        },
        handleOk(){
            if(this.express_no){
                let {id} = this.currentItem
                this.confirmLoading = true
                http.sendOrder({express_no:this.express_no, order_id:id}).then(res =>{
                    this.getData()
                    this.confirmLoading = false
                    this.expressShow = false
                })
            }else{
                this.$message.error('请填写快递单号')
                this.confirmLoading = false
            }
        },
        copyAddress(item, e){
            e.stopPropagation()
            let address = `${item.real_name}  ${item.phone}  ${item.province}${item.city}${item.detail}`
            this.$copyText(address).then(res=> {
                this.$message.success('复制成功')
            },error=>{
                this.$message.success('复制失败')
            })
        },
    }
}
</script>
<style lang="less" scoped>
.imgWrap{
    width: 120px;
    height: 70px;
    overflow: hidden;
    border-radius: 4px;
    img{
        width: 100%;
    }
}
.tableHeader{
    width: 100%;
    background: #eeeeee;
    display: flex;
    padding: 10px 0;
}
.orderHeader{
    background: #eeeeee;
    display: flex;
    padding: 10px 20px;
}
.order-table{
    min-width: 900px;
    padding: 10px;
    .order-table-body{
        margin-top: 10px;
    }
    .order-table-content{
        border: 1px solid #e6e8f0;
        border-top: none;
        border-right: none;
        display: flex;
        >div{
            min-width: 20px;
            border-right: 1px solid #e6e8f0;
            padding: 5px 10px;
            display: flex;
            align-items: center;
        }
        .vertical{
            flex-direction: column;
            justify-content: center;
            padding: 0;
            >.inline{
                display: block;
                width: 100%;
                border-bottom: 1px solid #e6e8f0;
                padding: 5px 10px;
                &:last-child{
                    border-bottom: 0;
                }
            }
        }
    }

}
.copy{
    color: royalblue;
    cursor: pointer;
}

</style>